import { template as template_e7421ff9b2774a17b3b9e8ddd823d73e } from "@ember/template-compiler";
import Component from '@glimmer/component';
type Signature = {
    Element: HTMLButtonElement;
};
export default class UnvoteButtonComponent extends Component<Signature> {
    static{
        template_e7421ff9b2774a17b3b9e8ddd823d73e(`
    <button type='button' class='flex items-center text-gray-400 hover:text-gray-500 px-2 hover:underline' data-test-unvote-button ...attributes>
      <span class='text-xs'>
        Cancel
      </span>
    </button>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
declare module '@glint/environment-ember-loose/registry' {
    export default interface Registry {
        'VotePage::IdeaCard::UnvoteButton': typeof UnvoteButtonComponent;
    }
}
